<template>
  <div class="container">
    <form class="form-register" ref="form-register" @submit.prevent="register">
      <div class="form-control">
        <input
          v-model="passwordFirst"
          type="password"
          name="passwordFirst"
          placeholder="Enter password"
        />
      </div>
      <div class="form-control">
        <input
          v-model="passwordSecond"
          type="password"
          name="passwordSecond"
          placeholder="Confirm password"
        />
      </div>
      <div class="form-control">
        <input
          v-model="clickupKey"
          type="text"
          name="password"
          placeholder="Enter ClickUp key"
        />
      </div>
      <div class="form-register__actions">
        <button type="submit" class="button button-primary">Register</button>
      </div>
    </form>
  </div>
</template>

<script>
import { showSnackbar } from '@/services'
import { mapMutations } from 'vuex'
import mtApi from '@/agGridV2/helpers/mt-api.helper'

export default {
  name: 'Register',

  data() {
    return {
      passwordFirst: '',
      passwordSecond: '',
      clickupKey: '',
      serviceName: 'spa'
    }
  },
  methods: {
    ...mapMutations(['setViewLoader']),
    async register() {
      if (this.passwordFirst !== this.passwordSecond) {
        showSnackbar({
          mode: 'error',
          text: 'Password mismatch'
        })
        return false
      }
      this.setViewLoader(true)

      try {
        await mtApi.registerUser({
          password: this.passwordFirst,
          password_confirmation: this.passwordSecond,
          clickup_user_api_key: this.clickupKey,
          device_name: this.serviceName
        })

        showSnackbar({
          mode: 'success',
          text: 'Register complete successfully'
        })

        setTimeout(() => {
          this.$router.push({ name: 'login' })
          this.setViewLoader(false)
        }, 3000)
      } catch (e) {
        console.log('sss', e)
      } finally {
        this.setViewLoader(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-register {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 600px;
  margin: 0 auto;
  padding: 60px;
  background-color: $bg-secondary;

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}
.form-control + .form-control {
  margin-top: 1rem;
}
</style>
